import React from "react"
import Info from "./components/Info"


export default function App() {
    return (
        <div className="container">
            <div className="bg"></div>
            <Info />
        </div>
    )
}